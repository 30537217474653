<template>
  <Dropdown v-if="user" theme="profile-menu" placement="bottom-end" ref="dropdownRef">
    <template #default>
      <div class="profile-menu">
        <Avatar size="lg" :url="user?.avatar" />
        <SvgIcon name="menu-1" class="text-key-500" :size="20" />
      </div>
    </template>
    <template #dropdown>
      <div class="flex flex-col gap-5 p-5 relative">
        <img v-if="user?.avatar" class="avatar-image" :src="user?.avatar" alt="avatar" />
        <ModalPopupButton class="absolute right-0 top-0" icon="crossmark" @click="closeDropdown" />
        <div class="flex flex-col italic text-2xl text-gray-900">
          <span class="font-extralight">{{ props.user?.first_name }}</span>
          <span class="font-extrabold flex items-center gap-1">
            {{ props.user?.last_name }}
          </span>
        </div>
        <div class="flex flex-col gap-[2px]">
          <DropdownMenuItem data-qa="profile" icon="user" @click="goToProfilePage">
            <div class="flex flex-row w-full items-center">
              <span class="grow">{{ $t('fanvestor.profile.profile') }}</span>
              <SvgIcon name="chevron-right" :size="20" />
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            v-if="!isClubUser"
            data-qa="FAQ"
            icon="circle-question"
            @click="goToFAQ"
          >
            <div class="flex flex-row w-full items-center">
              <span class="grow">{{ $t('fanvestor.profile.FAQ') }}</span>
              <SvgIcon name="chevron-right" :size="20" />
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem data-qa="logout" icon="logout" @click="onLogout">
            {{ $t('auth.logout') }}
          </DropdownMenuItem>
        </div>
        <div v-if="!isClubUser" class="sport-switcher" @click="switchSport">
          <div class="pt-0.5">{{ $t('fanvestor.profile.switch-to') }}</div>
          <Logo class="h-5 w-[134px]" secondary :hockey="$route.params.sport !== SPORTS.HOCKEY" />
        </div>
      </div>
    </template>
  </Dropdown>
  <Button
    v-else
    type="secondary"
    size="large"
    @click="
      $router.push({ name: LOGIN, params: { sport: +route.params.sport || SPORTS.FOOTBALL } })
    "
    >{{ $t('auth.login.login') }}</Button
  >
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import Avatar from '@/components/common/avatar/Avatar.vue'
import Button from '@/components/common/buttons/Button.vue'
import ModalPopupButton from '@/components/common/buttons/ModalPopupButton.vue'
import Dropdown from '@/components/common/dropdown/Dropdown.vue'
import DropdownMenuItem from '@/components/common/dropdown/DropdownMenuItem.vue'
import SvgIcon from '@/components/common/SvgIcon.vue'
import Logo from '@/components/page/Logo.vue'
import type { User } from '@/generated/api'
import { CLUB_PROFILE, FANVESTOR_PROFILE, LOGIN } from '@/modules/auth/routes'
import { useAuthStore } from '@/modules/auth/store'
import { UserRole } from '@/modules/auth/types'
import { SPORTS } from '@/utils/variables'

const authStore = useAuthStore()
const router = useRouter()
const route = useRoute()
const props = defineProps<{
  user?: User | null
}>()

const dropdownRef = ref<typeof Dropdown | null>(null)

const isClubUser = computed(() => props.user?.role === UserRole.ClubUser)

const onLogout = async () => {
  await authStore.logout()
  await router.push({ name: LOGIN })
}

const closeDropdown = () => {
  if (dropdownRef.value) {
    dropdownRef.value.hide()
  }
}

function goToProfilePage() {
  router.push({ name: isClubUser.value ? CLUB_PROFILE : FANVESTOR_PROFILE })
}

const goToFAQ = () => {
  window.open('https://www.crowd-sports.com/faq', '_blank')
}

const switchSport = () => {
  const sportToSwitchTo = route.params.sport === SPORTS.HOCKEY ? SPORTS.FOOTBALL : SPORTS.HOCKEY
  router.push({ name: route.name!, params: { sport: sportToSwitchTo } })
}
</script>

<style lang="postcss" scoped>
.profile-menu {
  @apply h-12 pl-1 pr-4 rounded-full flex gap-3 items-center border border-white-10 cursor-pointer;

  &:hover {
    @apply border-white-20 bg-white-5;
  }
}

.avatar-image {
  @apply h-24 w-24 object-cover rounded-full;
}

:deep(.menu-item) {
  @apply py-3 px-5 text-lg font-bold text-gray-600 bg-gray-100;
  &:not(.disabled):not(.active) {
    @apply hover:bg-gray-200 text-gray-600;
  }
}

.sport-switcher {
  @apply flex items-center justify-center gap-2 p-4 text-gray-500 text-xs caps font-bold border border-gray-200 rounded-2xl hover:border-gray-300 cursor-pointer;
}
</style>
